<template>
  <div
    :class="[
      plain ? 'bg-transparent border-0' : 'bg-whitesmoke rounded-lg border border-gray-lightest',
      isPadded ? 'p-4' : null,
    ]"
    class="fr-input-container box-border">
    <slot></slot>
  </div>
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps({
  plain: {
    type: Boolean,
    default: false
  },
  padded: {
    type: Boolean,
    default: true
  },
  unpadded: {
    type: Boolean,
    default: false
  }
})

const isPadded = computed(() => {
  if (props.unpadded) {
    return false
  }

  return props.padded
})
</script>
