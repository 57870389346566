<template>
  <div
    :class="[
      isPadded ? 'px-3 py-3' : null,
      hasError ? 'has-error' : null,
      !grid ? 'first:mt-0 mt-4' : null,
      other_class
    ]"
    class="fr-form-item flex-1 rounded-lg">
    <slot></slot>
    <div v-if="showErr && hasError">
      {{ err }}
    </div>
  </div>
</template>
<script setup>
import {computed} from 'vue'

const props = defineProps({
  padded: {
    type: Boolean,
    default() {
      return true
    }
  },
  unpadded: {
    type: Boolean,
    default: false
  },
  plain: {
    type: Boolean,
    default: false
  },
  errors: {
    type: Object
  },
  err: {
    type: String
  },
  showErr: {
    type: Boolean,
    default: false
  },
  grid: {
    type: Boolean,
    default: false
  },
  other_class: {
    type: String
  }
})

const hasError = computed(() => {
  if (props.errors) {
    return props.errors.errors[props.errors.id] ? true : false
  }

  return props.err ? true : false
})

const isPadded = computed(() => {
  if (props.grid) {
    return false
  }

  if (props.unpadded) {
    return false
  }

  return props.padded
})
</script>
<style scoped>
.fr-form-item :slotted(.fr-label) {
  @apply font-archerlight;
}
.has-error :slotted(.fr-input-container) {
  @apply border border-red-dark;
}
</style>
